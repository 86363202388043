import { FC, memo, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { setIsAuthInProgress, setUser } from 'store/auth';
import { selectUnsavedQRCode } from 'store/builder/selectors';
import { SocialSubmitPayload, UserCredential } from 'types/auth';
import { LINKS } from 'constants/routes';
import { showNotification } from 'config/toast';

import LoginForm from 'components/Auth/Forms/LoginForm';
import { useSaveQrCode } from 'hooks/useSaveQrCode';
import useRemoveUnsavedQRCodeDataFromStore from 'hooks/useRemoveUnsavedQRCodeDataFromStore';
import { UserStatusEnum } from 'types/subscriptions';
import { authApi } from 'api/auth';
import { getValidErrorMessage } from 'utils';
import { localStorageService } from 'libs/localStorage/local.storage.service';
import { useRouter } from 'next/router';
import { setAuthCookie } from 'utils/api';
import { UserRoleEnum } from 'constants/userAccess';
import { getCookie, itIsUnlockFlow, transformObject } from 'utils/common';
import { useTranslationEditorContext } from 'providers/TranslationEditor';
import i18n, { changeLanguage } from 'config/i18n';

import LandingLayout from 'containers/layouts/LandingLayout';
import { COLORS } from 'styles';
import { LoginFormWrapper } from './styled';
import { useSearchParams } from 'next/navigation';

type LoginContainerProps = {
  disableLayout?: boolean;
  onSignUpClick?: (open: boolean) => void;
};

const LoginContainer: FC<LoginContainerProps> = ({ disableLayout, onSignUpClick }) => {
  const dispatch = useDispatch();
  const qrCode = useSelector(selectUnsavedQRCode);
  const { i18n } = useTranslation();

  const router = useRouter();
  const removeUnsavedQRCodeDataFromStore = useRemoveUnsavedQRCodeDataFromStore();

  const searchParams = useSearchParams();
  const redirectTo = searchParams.get('redirectTo');

  const saveQrCode = useSaveQrCode({
    onError: useCallback((errorMessage: string) => {
      showNotification(errorMessage, 'error');
    }, []),
  });

  const updateUser = useCallback(
    async (data: any): Promise<void> => {
      const isAdmin = data.user.isAdminLogin;
      const is2FAEnabled = data.user.use2fa && !isAdmin;

      localStorageService.setScansLimitAlert(false);

      // Doing this after update of qr code, so qr code library data will be fetched after update.
      dispatch(setUser(data.user));
      setAuthCookie(data.accessToken, data.user.role);
      await changeLanguage(data.user.language);

      if (data.user.role === UserRoleEnum.USER) {
        localStorageService.removeLokaliseEditor();
      }

      // Doing this separately to update qr code before setting user, so qr code librry data will be fetched after update.
      localStorageService.setUser(data.user);
      localStorageService.setJWTokens({
        refreshToken: data.refreshToken,
        accessToken: data.accessToken,
      });

      if (qrCode) {
        localStorageService.setScansLimitAlert(false);

        await saveQrCode();

        removeUnsavedQRCodeDataFromStore();
      }

      if (is2FAEnabled) {
        dispatch(setIsAuthInProgress(false));
        router.push(`${LINKS.LOGIN_2FA}${window.location.search}`, undefined, { locale: i18n.language });
        return;
      }

      if (redirectTo) {
        router.push(`${redirectTo}`, undefined, {
          locale: i18n.language,
        });
      } else {
        router.replace(`${LINKS.CABINET_QR_CODES}${window.location.search}`, undefined, { locale: i18n.language });
      }

      if (data.user.status === UserStatusEnum.FREEMIUM_OLD) {
        localStorageService.setScansLimitAlert(true);
      }

      dispatch(setIsAuthInProgress(false));
    },
    [qrCode, router, dispatch, saveQrCode, removeUnsavedQRCodeDataFromStore]
  );

  const socialAuthSubmit = async (payload: SocialSubmitPayload): Promise<void> => {
    const { token, type, signup } = payload;
    if (!token) return;
    try {
      if (type === 'google') {
        const { data } = await authApi.googleLogin(token);
        updateUser(data);
      } else if (type === 'facebook') {
        const { data } = await authApi.facebookLogin(token);
        updateUser(data);
      } else if (type === 'apple') {
        const language = getCookie('lang') || i18n.language || navigator.language.split('-')[0] || navigator.language;
        const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        const isUnlockFlow = itIsUnlockFlow();
        if (signup) {
          const { data } = await authApi.appleSignup({ language, timezone, isUnlockFlow, type, token });
          updateUser(data);
        } else {
          const { data } = await authApi.appleSignupFinalize({ language, timezone, isUnlockFlow, type, token });
          updateUser(data);
        }
      }
    } catch (error) {
      console.error(error);
      showNotification(getValidErrorMessage(error), 'error');
    }
  };

  const onSubmit = useCallback(
    // eslint-disable-next-line consistent-return
    async (values: UserCredential) => {
      try {
        await dispatch(setIsAuthInProgress(true));
        const { userId } = router.query;

        const apiCall = userId ? authApi.becameUser : authApi.login;

        const { data } = await apiCall(
          transformObject(values, { email: (value) => value.trim() }),
          typeof userId === 'string' ? userId : ''
        );

        updateUser(data);
      } catch (error: any) {
        dispatch(setIsAuthInProgress(false));
        console.error(error);
        showNotification(getValidErrorMessage(error), 'error');
        return error;
      }
    },
    [dispatch, router, updateUser]
  );

  const onForgotPassword = useCallback(() => {
    router.push(LINKS.RECOVER_PASSWORD, undefined);
  }, [router]);

  const onSignUp = useCallback(() => {
    router.push(LINKS.SIGN_UP, undefined);
  }, [router]);

  const setSignUpFormVisible = () => {
    onSignUpClick?.(false);
  };

  const { t } = useTranslation();
  const { getDataAttributes } = useTranslationEditorContext();

  const loginFromWrapper = (
    <LoginFormWrapper>
      <h1 {...getDataAttributes('qr.auth.login')}>{t('qr.auth.login', 'Login')}</h1>
      <LoginForm
        onSubmit={onSubmit}
        onForgotPassword={onForgotPassword}
        onSignUp={disableLayout ? setSignUpFormVisible : onSignUp}
        onSocialSubmit={socialAuthSubmit}
      />
    </LoginFormWrapper>
  );

  if (disableLayout) {
    return loginFromWrapper;
  }

  return (
    <LandingLayout isLogged={false} hideLogin backgroundColor={COLORS.MAIN_BACKGROUND}>
      {loginFromWrapper}
    </LandingLayout>
  );
};

export default memo(LoginContainer);
