import styled from '@emotion/styled';
import { breakpoints, mediaMin } from 'styles';

export const ForgotPasswordContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 10px 0 30px;
  font-weight: 500;
`;

export const OrBlock = styled.span`
  text-align: center;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-around;
  flex-wrap: nowrap;
  line-height: 22px;
  margin: 12px 0;
  font-weight: 500;
  font-size: 18px;
  opacity: 0.6;

  ${mediaMin(breakpoints.tabletM)} {
    margin: 14px 0;
  }
`;

export const ButtonBox = styled.div`
  display: flex;
  gap: 14px;
  justify-content: center;
  flex-direction: column;
  margin: 0 auto;
`;
