import { FC, memo } from 'react';
import InputAdornment from '@mui/material/InputAdornment';
import { Email } from 'components/ui/icons/Common';
import InputOutlined, { InputOutlinedProps } from '../InputOutlined';

type InputEmailProps = InputOutlinedProps;
const InputEmail: FC<InputEmailProps> = ({ className, ...props }) => {
  return (
    <InputOutlined
      {...props}
      className={className}
      InputProps={{
        endAdornment: (
          <InputAdornment style={{ width: 24, marginRight: 15 }} position="start">
            <div style={{ width: 24, height: 24, padding: 0, pointerEvents: 'none' }}>
              <Email />
            </div>
          </InputAdornment>
        ),
      }}
    />
  );
};

export default memo(InputEmail);
