import styled from '@emotion/styled';
import { COLORS } from 'styles/index';

export const InputContainer = styled.div`
  display: grid;
  width: 100%;
  box-sizing: border-box;
  font-family: 'Rubik';

  ${`label[data-shrink="true"]`} {
    padding: unset;
    color: ${COLORS.BUTTON_SECONDARY};
    font-size: 14px;
    font-weight: 500;
    transform: translate(18px, -8px);

    &.Mui-error {
      color: ${COLORS.RED500};
    }
  }

  &.valid {
    .MuiOutlinedInput-root:not(.Mui-focused) {
      background: transparent;

      &:hover {
        background: white;
      }
    }
    ${`label[data-shrink="true"]`} {
      color: #adb0b5;
    }
  }

  .MuiFormLabel-root-MuiInputLabel-root .Mui-error {
    color: ${COLORS.BLUE500};
  }

  .MuiFormLabel-root-MuiInputLabel-root .Mui-focused {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;
    color: ${COLORS.BLUE500};
  }

  label.Mui-focused {
    color: ${COLORS.BLUE500} !important;
    opacity: 1;
    transition: 0.5s ease all;
  }

  .MuiOutlinedInput-root {
    min-height: 50px;
    height: unset;
    border-color: ${COLORS.ICON_GREY};
    padding: 0;
    width: 100%;
    border-width: 1px;
    background: white;
    box-shadow: none !important;

    legend {
      font-size: 14px;
      margin-left: 4px;

      span {
        padding: 0 9px 0 0;
        font-size: 14px;
        font-weight: 600;
      }
    }

    &:hover {
      background-color: white;

      .MuiOutlinedInput-notchedOutline {
        border-color: ${COLORS.BUTTON_SECONDARY};
      }
    }
    .MuiOutlinedInput-notchedOutline {
      border-width: 1px;
      border-color: ${COLORS.ICON_GREY};
      border-radius: 6px;

      &:hover {
        /* 1E1D20 */
        border-color: red !important;
        font-size: 14px;
      }
    }

    &:empty {
      background: red;
    }

    &.Mui-focused {
      border-width: 1px !important;
      border-color: ${COLORS.ICON_GREY} !important;
      background-color: transparent;
      transition: 0.5s ease all;

      svg {
        path {
          fill: ${COLORS.BLUE500};

          &.stroke {
            stroke: ${COLORS.BLUE500};
          }
        }
        circle {
          &.stroke {
            stroke: ${COLORS.BLUE500};
          }
        }
      }

      .MuiOutlinedInput-notchedOutline {
        border-color: ${COLORS.BLUE} !important;
        outline-color: ${COLORS.BLUE} !important;
        border-width: 1px;
      }
    }

    &.Mui-error:not(.Mui-focused) {
      border-color: ${COLORS.RED500};
      background: transparent;
      transition: 0.5s ease all;

      .MuiOutlinedInput-notchedOutline {
        border-width: 1px !important;
        border-color: ${COLORS.RED500};
        outline-color: ${COLORS.RED500};
      }

      svg {
        path {
          fill: ${COLORS.RED500};

          &.stroke {
            stroke: ${COLORS.RED500};
          }
        }
        circle {
          &.stroke {
            stroke: ${COLORS.RED500};
          }
        }
      }
    }
  }

  .MuiOutlinedInput-input {
    padding: 15px 20px 15px 19px;
    box-sizing: border-box;
    height: 48px;
    font-weight: 400;
    font-style: normal;
    font-size: 16px;
    line-height: 1.25;

    &[type='password'] {
      line-height: 0;
    }

    svg {
      color: #a0a4ad;
    }

    &:focus {
      background-color: transparent;
    }

    &::placeholder {
      color: #9a9a9a;
      font-weight: 400;
    }

    // hide default MS Edge eye icon
    &::-ms-reveal,
    &::-ms-clear {
      display: none;
    }
  }

  .MuiInputLabel-root {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 16px;
    padding-left: 0;
    color: #70767e;
  }

  .MuiFormHelperText-root {
    min-height: 16px;
    margin-bottom: 13px;
    margin-top: 4px;
    margin-left: 4px;
    font-size: 12px;
    line-height: 130%;
    font-weight: 500;

    &:empty {
      margin-bottom: 0;
    }
  }
`;
