import { FC, memo, useState, useCallback } from 'react';
import InputAdornment from '@mui/material/InputAdornment';
import { Visibility, VisibilityOff } from '../icons/Common';
import InputOutlined, { InputOutlinedProps } from '../InputOutlined';

const InputPassword: FC<InputOutlinedProps> = ({ className, ...props }) => {
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const handleClickShowPassword = useCallback(() => setShowPassword(!showPassword), [showPassword]);

  return (
    <InputOutlined
      {...props}
      className={className}
      type={showPassword ? 'text' : 'password'}
      autoComplete="off"
      InputProps={{
        endAdornment: (
          <InputAdornment style={{ width: 24, marginRight: 15 }} position="start">
            <div
              style={{ width: 24, height: 24, padding: 0, cursor: 'pointer' }}
              onClick={handleClickShowPassword}
              aria-hidden="true"
            >
              {showPassword ? <Visibility /> : <VisibilityOff />}
            </div>
          </InputAdornment>
        ),
      }}
    />
  );
};

export default memo(InputPassword);
