/* eslint-disable no-console */
import { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { qrCodeApi } from 'api/qrCode/apis';
import { createQRCodeDTO } from 'api/qrCode/entities';
import { selectUnsavedQRCode, selectUnsavedQRCodeUpdateTarget } from 'store/builder/selectors';
import { getValidErrorMessage } from 'utils/index';
import { setUnsavedQRCode, setUnsavedQRCodeUpdateTarget } from 'store/builder/index';

type UseSaveQrCodeProps = {
  preload?: () => void;
  postLoad?: () => void;
  onError?: (error: string) => void;
  onSave?: () => void;
};

type UseSaveQrCodeInfo = {
  saveQrCode: () => Promise<void | undefined>;
};

export const useSaveQrCode = ({
  preload,
  onError,
  postLoad,
  onSave,
}: UseSaveQrCodeProps): UseSaveQrCodeInfo['saveQrCode'] => {
  const qrCode = useSelector(selectUnsavedQRCode);
  const qrCodeThatNeedsToBeUpdated = useSelector(selectUnsavedQRCodeUpdateTarget);
  const dispatch = useDispatch();

  return useCallback(async () => {
    try {
      if (!qrCode) {
        console.error('Qr code is empty');
        return;
      }
      preload && preload();
      if (qrCodeThatNeedsToBeUpdated) {
        await qrCodeApi.update(qrCodeThatNeedsToBeUpdated.id, qrCode);
        dispatch(setUnsavedQRCode(undefined));
        dispatch(setUnsavedQRCodeUpdateTarget(undefined));
      } else {
        const qrCodeDTO = createQRCodeDTO(qrCode);
        if (!qrCodeDTO.id) {
          const qrCodeCreationResponse = await qrCodeApi.createAsGuest(qrCodeDTO);
          await qrCodeApi.publish(qrCodeCreationResponse.id);
        } else {
          await qrCodeApi.updateAsGuest(qrCodeDTO.id, qrCodeDTO);
          await qrCodeApi.publish(qrCodeDTO.id);
        }

        dispatch(setUnsavedQRCode(undefined));
      }
      onSave?.();
    } catch (error) {
      console.error(error);
      onError && onError(getValidErrorMessage(error));
    } finally {
      postLoad && postLoad();
    }
  }, [qrCode, preload, qrCodeThatNeedsToBeUpdated, dispatch, onError, postLoad]);
};
