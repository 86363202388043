/* eslint-disable no-console */
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { setUnsavedQRCode, setUnsavedQRCodeUpdateTarget } from 'store/builder/index';

const useRemoveUnsavedQRCodeDataFromStore = (): (() => Promise<void | undefined>) => {
  const dispatch = useDispatch();

  return useCallback(async () => {
    dispatch(setUnsavedQRCode(undefined));
    dispatch(setUnsavedQRCodeUpdateTarget(undefined));
  }, [dispatch]);
};

export default useRemoveUnsavedQRCodeDataFromStore;
