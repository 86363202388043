import styled from '@emotion/styled';

export const AuthProviderButton = styled.button`
  width: 100%;
  max-width: 421px;
  height: 50px;
  color: #1e1d20;
  text-align: center;
  font-weight: 500;
  font-size: 16px;
  display: flex;
  align-content: stretch;
  justify-content: center;
  align-items: center;
  border: 1px solid #d9dce1;
  border-radius: 6px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  gap: 15px;
`;
