import styled from '@emotion/styled';
import MUIButton from '@mui/material/Button';
import { breakpoints, mediaMax, COLORS } from '../../styles';
import Lnk from 'next/link';

type InfoContainerProps = {
  login?: true;
};

export const Button = styled(MUIButton)`
  width: 100%;
  min-height: 50px;

  display: flex;
  align-items: center;
  text-align: center;

  background: #fe8c25;
  border-radius: 6px;

  font-family: 'Rubik';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 130%;

  text-transform: none;
  margin: 0 auto;
  color: #ffffff;

  &.Mui-disabled {
    background: #a0a4ad;

    font-weight: 700;
    font-size: 20px;
    line-height: 130%;

    color: rgba(255, 255, 255, 0.5);
    &:hover {
      background: #8c8c8c;
    }
  }

  &:hover {
    background: #fe8c25;
    box-shadow: 0px 6px 20px rgba(84, 56, 13, 0.16);
  }
`;

export const SignUpButton = styled(Button)`
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 130%;

  margin-top: 0;
`;

export const FormContainer = styled.div`
  width: 100%;
  margin-top: 50px;
  width: 100%;

  @media (max-width: ${breakpoints.tabletXS}px) {
    margin-top: 24px;
    min-width: unset;
  }

  @media (max-width: ${breakpoints.mobileL}px) {
    margin-top: 15px;
  }
`;

export const Form = styled.form`
  width: 100%;
`;

export const Link = styled.a`
  align-items: flex-end;
  font-style: normal;
  font-size: 16px;
  line-height: 19px;
  color: #5f81d5;
  cursor: pointer;
  text-decoration: none;

  @media (max-width: ${breakpoints.mobileS}px) {
    font-size: 14px;
  }
`;

export const InfoContainer = styled.div<InfoContainerProps>`
  font-weight: 400;
  font-size: 16px;
  line-height: 130%;

  margin-top: 28px;

  color: ${COLORS.PRIMARY_TEXT};
  text-align: center;
  span {
    @media (max-width: ${breakpoints.mobileM}px) {
      font-size: 14px;
    }
  }

  ${mediaMax(breakpoints.tabletXS)} {
    span {
      min-height: 21px;
    }
  }
`;

export const InfoText = styled.h4`
  font-style: normal;
  font-size: 16px;
  line-height: 200.98%;
  /* or 32px */

  color: #8f96a3;
`;

export const HintContainer = styled.div`
  margin-top: 28px;
  margin-bottom: 0;

  display: flex;
  align-items: center;
  justify-content: center;
  justify-items: center;
  text-align: center;
  ${mediaMax(breakpoints.tabletXS)} {
    margin-top: 53px;
    justify-content: flex-start;
    justify-items: flex-start;
    text-align: left;
  }
  ${mediaMax(breakpoints.mobileL)} {
    margin-top: 20px;
  }
`;

export const Hint = styled.h5`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 130%;
  text-align: center;

  margin-bottom: 0;
  margin-top: 0;
  color: ${COLORS.PRIMARY_TEXT};
  ${mediaMax(breakpoints.mobileM)} {
    font-size: 14px;
  }
`;

export const HintLink = styled(Lnk)`
  font-style: normal;
  font-size: 16px;
  line-height: 130%;
  text-decoration: none;
  text-transform: none;
  color: ${COLORS.BLUE500};
  ${mediaMax(breakpoints.mobileM)} {
    font-size: 14px;
  }
`;

export const HeaderText = styled.h3`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 130%;
  /* or 21px */

  color: #676e7c;

  margin-bottom: 24px;
`;
