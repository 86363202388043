/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'store';

export const selectBuilder = (state: RootState) => state.builder;

export const selectUnsavedQRCode = createSelector([selectBuilder], (builder) => builder.unsavedQRCode);

export const selectUnsavedQRCodeUpdateTarget = createSelector(
  [selectBuilder],
  (builder) => builder.unsavedQRCodeUpdateTarget
);
