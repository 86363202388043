import { useCallback } from 'react';
import { FormikProps } from 'formik';

export type CallbackChangeEvent =
  | (Event & { target: { value: unknown; name: string } })
  | React.ChangeEvent<HTMLInputElement>
  | React.ChangeEvent<{ name: string; value: string }>;

export type UseTouchHandleChangeConfig = {
  setFieldTouched: FormikProps<any>['setFieldTouched'];
  handleChange: FormikProps<any>['handleChange'];
};

const useTouchHandleChange = (config: UseTouchHandleChangeConfig): ((e: CallbackChangeEvent) => void) => {
  const touchHandleChange = useCallback(
    (e: CallbackChangeEvent) => {
      config?.setFieldTouched(e.target.name, true);
      config?.handleChange(e);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [config?.setFieldTouched, config?.handleChange]
  );

  return touchHandleChange;
};

export default useTouchHandleChange;
