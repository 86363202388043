/* eslint-disable react/react-in-jsx-scope */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-expect-error
import FacebookAuth from 'react-facebook-auth';
import Image from 'next/image';
import TranslationSpan from 'components/ui/TranslationSpan';
import { AuthProviderButton } from '../AuthProviderButton';

const FACEBOOK_APP_ID = process.env.REACT_APP_FACEBOOK_APP_ID || '852634732394583';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const MyFacebookButton = ({ onClick }: any) => (
  <AuthProviderButton onClick={onClick}>
    <Image src="/icons/common/facebook.svg" height={26} width={26} alt="fb" />
    <TranslationSpan phrase="qr.auth.facebook" fallback="Continue with Facebook" />
  </AuthProviderButton>
);

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const FacebookBtn = ({ onSubmit }: any) => {
  return (
    <FacebookAuth
      isMobile={false}
      disableRedirect
      appId={FACEBOOK_APP_ID}
      callback={onSubmit}
      component={MyFacebookButton}
    />
  );
};
