import { memo, FC } from 'react';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import FormHelperText from '@mui/material/FormHelperText';
import { InputContainer } from './styled';

export type InputOutlinedProps = TextFieldProps & {
  withFixedHeightError?: boolean;
  maxLength?: string | number;
};

const InputOutlined: FC<InputOutlinedProps> = ({
  label,
  helperText,
  className,
  withFixedHeightError = true,
  maxLength,
  inputProps,
  InputLabelProps,
  ...props
}) => {
  return (
    <InputContainer className={className}>
      <TextField
        {...props}
        inputProps={{
          maxLength,
          ...inputProps,
        }}
        label={label}
        variant="outlined"
        InputLabelProps={{ ...InputLabelProps }}
      />
      {helperText || withFixedHeightError ? <FormHelperText error={props.error}>{helperText}</FormHelperText> : null}
    </InputContainer>
  );
};

export default memo(InputOutlined);
