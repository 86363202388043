/* eslint-disable react/react-in-jsx-scope */
import Image from 'next/image';
import TranslationSpan from 'components/ui/TranslationSpan';
import { useGoogleLogin, GoogleOAuthProvider } from '@react-oauth/google';
import { FC } from 'react';
import { AuthProviderButton } from '../AuthProviderButton';

type FacebookBtnProps = {
  onSubmit: (data: any) => void;
};

const CustomBtn: FC<FacebookBtnProps> = ({ onSubmit }) => {
  const login = useGoogleLogin({
    onSuccess: (tokenResponse: { access_token: string }) => {
      onSubmit(tokenResponse?.access_token);
    },
    onError: (e) => console.log('Login Failed', e),
  });

  return (
    <AuthProviderButton onClick={() => login()}>
      <Image src="/icons/common/google.svg" height={26} width={26} alt="google" />
      <TranslationSpan phrase="qr.sign.up.google" fallback="Continue with Google" />
    </AuthProviderButton>
  );
};

export const GoogleBtn: FC<FacebookBtnProps> = ({ onSubmit }) => {
  if (!process.env.REACT_APP_GOOGLE_AUTH_CLIENT_ID) {
    console.error('Empty google auth client id');
    return null;
  }

  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_AUTH_CLIENT_ID as string}>
      <CustomBtn onSubmit={onSubmit} />
    </GoogleOAuthProvider>
  );
};
