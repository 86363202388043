import { AuthProviderButton } from '../AuthProviderButton';
import Image from 'next/image';
import { useTranslationWithAttributes } from 'hooks';
import Script from 'next/script';
import { useEffect } from 'react';
import { ROUTES } from 'constants/api.routes';
import { BASE_URL } from '../../../../constants/api';
import { NEXT_PUBLIC_APPLE_OAUTH_CLIENT_ID, ENV, LINKS } from 'constants/constants';

export enum APPLE_AUTH_TYPE {
  Signup,
  Login,
}

const authTypeToAPIURL: Record<APPLE_AUTH_TYPE, string> = {
  [APPLE_AUTH_TYPE.Signup]: `${BASE_URL}${ROUTES.AUTH.APPLE_SIGNUP}`,
  [APPLE_AUTH_TYPE.Login]:
    ENV === 'development' ? `${BASE_URL}${ROUTES.AUTH.APPLE_LOGIN}` : `${BASE_URL}${LINKS.LOGIN}`,
};

const APPLE_AUTH_SCRIPT = 'https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js';

export const AppleAuthButton = ({
  authType,
  onSubmit,
}: {
  authType: APPLE_AUTH_TYPE;
  onSubmit: (token: string, signup: boolean) => void;
}) => {
  const [labelTranslation, labelAttributes] = useTranslationWithAttributes('qr.auth.apple');
  const clientId = NEXT_PUBLIC_APPLE_OAUTH_CLIENT_ID || '';
  const redirectURI = authTypeToAPIURL[authType];
  const formPost = authType == APPLE_AUTH_TYPE.Signup || ENV == 'development';
  const handleClick = async () => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window?.AppleID?.auth?.init({
      clientId,
      scope: formPost ? 'email' : '',
      redirectURI,
      responseMode: formPost ? 'form_post' : 'query',
      usePopup: ENV !== 'development',
    });
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window?.AppleID?.auth?.signIn();
  };

  useEffect(() => {
    const handleAuthSuccess = (event: any) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const signup = ENV !== 'development' && authType === APPLE_AUTH_TYPE.Signup;
      const idToken =
        (authType === APPLE_AUTH_TYPE.Signup
          ? event?.detail?.authorization?.code
          : event?.detail?.authorization?.id_token) || '';
      if (idToken) {
        onSubmit(idToken, signup);
      }
    };

    document.addEventListener('AppleIDSignInOnSuccess', handleAuthSuccess);

    return () => {
      document.removeEventListener('AppleIDSignInOnSuccess', handleAuthSuccess);
    };
  }, [onSubmit]);

  return (
    <>
      <Script type="text/javascript" src={APPLE_AUTH_SCRIPT} />
      <AuthProviderButton onClick={handleClick} {...labelAttributes}>
        <Image src="/icons/common/apple-logo.svg" height={26} width={26} alt="google" />
        {labelTranslation}
      </AuthProviderButton>
    </>
  );
};
