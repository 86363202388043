import styled from '@emotion/styled';
import { mediaMin, breakpoints } from 'styles';

export const LoginFormWrapper = styled.div`
  max-width: calc(100% - 40px);
  margin: 25px auto 0;
  padding-bottom: 152px;

  ${mediaMin(breakpoints.tabletXS)} {
    max-width: 420px;
  }
  ${mediaMin(breakpoints.tabletM)} {
    margin-top: 86px;
  }

  h1 {
    text-align: center;
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 25px;

    ${mediaMin(breakpoints.tabletM)} {
      font-size: 26px;
      line-height: 31px;
      margin-bottom: 56px;
    }
  }
`;
