import * as yup from 'yup';
import isEmail from 'validator/lib/isEmail';
import { PASSWORD } from 'constants/regex';

export const passwordValidation = yup
  .string()
  .trim()
  .min(8, 'qr.auth.error.password.min')
  .max(100, 'qr.auth.error.password.max')
  .matches(PASSWORD, 'qr.auth.error.password.invalid')
  .required('qr.auth.error.password.required');

export const emailValidation = yup
  .string()
  .trim()
  .required('qr.auth.error.email.required')
  .test('is-email', 'qr.auth.error.email.invalid', (value) => !!value && isEmail(value));

export const createNewPasswordValidationSchema = yup.object({
  password: passwordValidation,
  confirmPassword: passwordValidation.oneOf([yup.ref('password'), null], 'qr.auth.error.password.match'),
});

export const recoverPasswordValidationSchema = yup.object({
  email: emailValidation,
});

export const signUpValidationSchema = yup.object({
  email: emailValidation,
  password: passwordValidation,
});
