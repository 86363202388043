import { useCallback, useState } from 'react';
import { LINKS } from 'constants/constants';
import { useRouter } from 'next/router';

type UseAuthPayload = {
  onLogin: () => void;
  onRegister: () => void;
  handleFocus: () => void;
  handleBlur: () => void;
  isEmailError: boolean;
};

const useAuth = (): UseAuthPayload => {
  const router = useRouter();
  const [isEmailError, setIsEmailError] = useState(true);
  const onLogin = useCallback(() => {
    router.push(LINKS.LOGIN, router);
  }, [router]);

  const onRegister = useCallback(() => {
    router.push(LINKS.SIGN_UP, router);
  }, [router]);

  const handleFocus = useCallback(() => {
    setIsEmailError(false);
  }, []);

  const handleBlur = useCallback(() => {
    setIsEmailError(true);
  }, []);

  return { onLogin, onRegister, handleFocus, handleBlur, isEmailError };
};

export default useAuth;
