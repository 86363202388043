import { useRouter } from 'next/router';
import { FC, memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import { useTranslationEditorContext } from 'providers/TranslationEditor';

import InputPassword from 'components/ui/InputPassword';
import InputEmail from 'components/ui/InputEmail';
import useTouchHandleChange from 'hooks/useTouchHandleChange';
import useAuth from 'hooks/useAuth';
import { SocialSubmitPayload, UserCredential } from 'types/auth';
import { useIsomorphicLayoutEffect } from 'hooks';
import { signUpValidationSchema } from '../../validation';

import { GoogleBtn } from '../GoogleBtn';
import { Button, Link, FormContainer, Form, InfoContainer } from '../../styled';
import { ForgotPasswordContainer, ButtonBox, OrBlock } from './styled';
import { FacebookBtn } from '../FacebookBtn';
import TranslationSpan from 'components/ui/TranslationSpan';
import { APPLE_AUTH_TYPE, AppleAuthButton } from '../AppleAuthButton/AppleAuthButton';
import * as process from 'node:process';
import { NEXT_PUBLIC_APPLE_OAUTH_CLIENT_ID } from '../../../../constants/constants';

export type LoginFormProps = {
  onSubmit: (values: UserCredential) => void;
  onSocialSubmit: (payload: SocialSubmitPayload) => void;
  onSignUp: () => void;
  onForgotPassword: () => void;
};

const LoginForm: FC<LoginFormProps> = ({ onSubmit, onSocialSubmit, onForgotPassword, onSignUp }) => {
  const router = useRouter();
  const { t } = useTranslation();
  const { getDataAttributes, refresh } = useTranslationEditorContext();
  const { isEmailError, handleFocus, handleBlur } = useAuth();
  const [isComponentMounted, setIsComponentMounted] = useState(false);

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },

    validationSchema: signUpValidationSchema,
    onSubmit,
  });

  const touchHandleChange = useTouchHandleChange(formik);

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const responseGoogle = (accessToken: string) => {
    if (!accessToken) return;
    onSocialSubmit({ token: accessToken, type: 'google' });
  };

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const responseFacebook = ({ accessToken }: any) => {
    if (!accessToken) return;
    onSocialSubmit({ token: accessToken, type: 'facebook' });
  };

  const handleAppleSubmit = (token: string, signup?: boolean) => onSocialSubmit({ token, type: 'apple', signup });

  useEffect(() => {
    const { code, token, email } = router.query;
    if (code && token && email) {
      onSocialSubmit({ token: token as string, type: 'apple' });
    }
  }, [router, onSocialSubmit]);

  useIsomorphicLayoutEffect(() => {
    setIsComponentMounted(true);
  }, []);

  useEffect(() => {
    refresh();
  }, [formik.errors, formik.touched, refresh]);

  return (
    <FormContainer>
      <Form onSubmit={formik.handleSubmit}>
        <InputEmail
          id="email"
          name="email"
          autoComplete="username"
          disabled={!isComponentMounted}
          label={t('qr.auth.email.label', 'Email')}
          value={formik.values.email}
          onChange={touchHandleChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
          error={isEmailError && formik.touched.email && Boolean(formik.errors.email)}
          helperText={
            isEmailError && formik.touched.email && formik.errors.email ? (
              <span {...getDataAttributes(formik.errors.email as string)}>{t(formik.errors.email as string)}</span>
            ) : null
          }
          inputProps={getDataAttributes('qr.auth.email.placeholder', 'placeholder')}
          className={formik.touched.email && !formik.errors.email ? 'valid' : ''}
        />
        <InputPassword
          id="password"
          name="password"
          autoComplete="off"
          disabled={!isComponentMounted}
          label={t('qr.auth.password.label', 'Password')}
          value={formik.values.password}
          onChange={touchHandleChange}
          error={formik.touched.password && Boolean(formik.errors.password)}
          helperText={
            formik.touched.password &&
            formik.errors.password && (
              <span {...getDataAttributes(formik.errors.password)}>{t(formik.errors.password)}</span>
            )
          }
          inputProps={getDataAttributes('qr.auth.password.placeholder', 'placeholder')}
          className={formik.touched.password && !formik.errors.password ? 'valid' : ''}
        />

        <ForgotPasswordContainer>
          <Link onClick={onForgotPassword} {...getDataAttributes('qr.auth.forgot.password')}>
            {t('qr.auth.forgot.password', 'Forgot password')}
          </Link>
        </ForgotPasswordContainer>

        <Button
          // disabled={!formik.isValid && !formik.isSubmitting}
          type="submit"
          {...getDataAttributes('qr.auth.login')}
        >
          {t('qr.auth.login', 'Login')}
        </Button>
      </Form>
      <OrBlock>
        <TranslationSpan phrase="qr.auth.log.in.or" fallback="or" />
      </OrBlock>
      <ButtonBox>
        <GoogleBtn onSubmit={responseGoogle} />
        <FacebookBtn onSubmit={responseFacebook} />
        {NEXT_PUBLIC_APPLE_OAUTH_CLIENT_ID ? (
          <AppleAuthButton authType={APPLE_AUTH_TYPE.Login} onSubmit={handleAppleSubmit} />
        ) : null}
      </ButtonBox>
      <InfoContainer login>
        <TranslationSpan phrase="qr.auth.log.in.hint.signup" fallback="Don’t have an account? " />
        <Link onClick={onSignUp}>
          <TranslationSpan phrase="qr.auth.sign.up" fallback="Sign up" />
        </Link>
      </InfoContainer>
    </FormContainer>
  );
};

export default memo(LoginForm);
